import type { NotificationTypes } from '../notifications/jNotification'

interface JSiteFlagsNotifications {
  enabled: boolean
  type: NotificationTypes
}

interface JSiteFlagsActions {
  enabled: boolean
  type: string
}

interface JSiteIntegration {
  configuration: any
  type: string
}

interface JSiteFlagSampling {
  recompute: boolean
}

interface JSiteDashboard {
  id: number
  name: string
}

interface JSiteFavorites {
  type: string
  value: string
}

export interface JSiteDecimal {
  decimals_number: number
  as_tolerance: boolean
  enabled: boolean
}
interface JSiteHistory {
  enabled: boolean
}

interface JSiteApplicationFields {
  operations: boolean
  products: boolean
  workplaces: boolean
}

export interface JSiteFlags {
  actions: JSiteFlagsActions[]
  idle_delay: number
  notifications: JSiteFlagsNotifications[]
  application_fields: JSiteApplicationFields
  password_expiration_period: number | null
  media_library: any
  analytics: boolean
  new_operator_tiles: boolean
  sampling: JSiteFlagSampling
  pilot_mode: boolean
  routing_mode: 'auto' | 'manual'
  multi_tab_mode: boolean
  decimals: JSiteDecimal
  integration: JSiteIntegration
  history: JSiteHistory
  workplace_documents: boolean
  mes: boolean
  use_sso_authentication: boolean
}

interface JSiteChecksums {
  operations: any
  products: any
  users: any
  workorders: any
  workplaces: any
  routing: any
  lookups: any
}

export interface JSite {
  id: string
  client_id: string
  dashboards: JSiteDashboard[]
  favorites: JSiteFavorites[]
  client_site_id: string
  created_date: Date
  flags: JSiteFlags
  name: string
  checksums: JSiteChecksums
}

export enum RoutingMode {
  AUTO = 'auto',
  MANUAL = 'manual',
}
